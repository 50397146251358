<template>
    <div :class="`popup ${isAdmin? 'initPwPopup':''}`" :v-if="popupVisible">
        <div class="box step1Pop">
            <p class="popTitle">{{isAdmin?'비밀번호 초기화':'비밀번호 변경'}}</p>
            <ul class="cont" style="padding:8px 24px;">
                <li>
                    <v-form ref="pwForm" lazy-validation>
                        <table class="whiteTbl">
                            <caption>비밀번호 변경 입력</caption>
                            <colgroup>
                                <col style="width:150px" />
                                <col />
                            </colgroup>
                            <tbody v-if="!isAdmin">
                                <tr>
                                    <th>현재 비밀번호</th>
                                    <td>
                                        <Input :label="''" :type="'password'" :name="'oldPw'" :required=true
                                            :width="'fullWidth'" :autocomplete="'off'" v-model="formValues.oldPw"
                                            :fnName="notNullRules('현재 비밀번호')" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>새 비밀번호</th>
                                    <td>
                                        <Input :label="''" :type="'password'" :name="'newPw'" :required=true
                                            :width="'fullWidth'" :autocomplete="'off'" v-model="formValues.newPw"
                                            :fnName="passwordRules(formValues.newPw)" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>새 비밀번호 확인</th>
                                    <td>
                                        <Input :label="''" :type="'password'" :name="'chkPw'" :required=true
                                            :width="'fullWidth'" :autocomplete="'off'" v-model="formValues.chkPw"
                                            :fnName="confirmPasswordRules(formValues.newPw, formValues.chkPw)" />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <th>임시비밀번호 입력</th>
                                    <td>
                                        <div class="input-wrapper" style="width: 100%">
                                            <v-text-field name="newPw" maxLength="20" required="true" v-model="formValues.newPw" density="compact" hide-details="auto" flat solo
                                                :type="showPassword? 'text':'password'" 
                                                :rules="notNullRules('비밀번호')"
                                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                @click:append="showPassword = !showPassword"
                                            />
                                            <span v-if="error">{{ errorMsg }}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            
                        </table>
                    </v-form>
                </li>
            </ul>
            <div class="btnW popBtn mt16">
                <button v-if="initPwYn === 'N'" class="btn default" @click="closePopup()" v-ripple>{{CONST_CANCEL}}</button>
                <button class="btn solid" @click="save()" v-ripple>{{CONST_MOD}}</button>
            </div>
        </div>
        <v-dialog v-model="openPopup" width="320">
            <DefaultPopup
                :popupText="msg"
                :secondTxt="CONST_OK"
                :firstBtn="false"
                :onClick="() => { popupProgress(isError) }"
                @closePopup="openPopup = false"
            />
        </v-dialog>
    </div>
</template>

<script>
import API_CHANGE_PW from '../API/user/changePw'
import { rules } from '../mixin/rules'
export default {
    components: {
    },
    mixins: [rules],
    props: {
        popupVisible: {
            default: false
        },
         onClick: {
            default: () => { }
        },
        userNo: {
            default: ''
        },
        initialValues: {
            type: Object,
            default: () => ({})
        },
        isAdmin: {
            default: false
        },
        initPwYn: {
            default: "N"
        }
    },
    data() {
        return {
            formValues: { ...this.initialValues },
            openPopup: false,
            msg: '',
            isError: false,
            showPassword: true,
            error: false,
        };
    },
    watch: {},
    created() { },
    mounted() {
    },
    destroyed() { },
    methods: {
        resetForm() {
            this.formValues = {
                oldPw: '',
                newPw: '',
                chkPw: ''
            },
            this.showPassword = true
        },
        closePopup() {
            this.resetForm();
            this.$emit('closePopup');
            this.$refs.pwForm.resetValidation();
        },
        async save() {
            // 유효성 검사
            const validate = this.$refs.pwForm.validate();
            if (validate) {
                let res = await API_CHANGE_PW.request(this.userNo,this.formValues.oldPw, this.formValues.newPw, this.isAdmin)
                if (res.isSuccess) { // 성공
                    this.isError = false;
                    this.msg = '비밀번호가 변경되었습니다.';
                    this.openPopup = true;
                } else { // 실패
                    this.showPopup(res.errorMsg, res.status);
                }
            }
        },
        popupProgress(isError) {
            if (!isError) {
                this.closePopup();
            }
            this.openPopup = false;
        }
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.popup .box{ width: 580px;}

::v-deep .input-wrapper {
    display: flex;
    //margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
    .v-input__slot{
        padding:0 !important;
        margin-bottom:5px;
    }
    .v-text-field__details{
        padding:0;
        margin-bottom:0;
    }
    .v-input__control{
        min-height: auto !important;
    }
}

::v-deep input[type=number]::-webkit-inner-spin-button, 
::v-deep input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

</style>
